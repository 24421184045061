/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title, image, large }: any) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `viewport`,
          content: `minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no`
        },
        {
          name: "keywords",
          content: "movie reviews, LenoJeno, classic films, Leonard Hockerts"
        },
        {
          name: "google-site-verification",
          content: "TRQcfLQBG2TTFtiFATau14Ai32PX9rid3KeheS1Xbws"
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `image`,
          content: image ? `${site.siteMetadata.siteUrl}${image}` : null
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: description ? description : metaDescription
        },
        {
          name: `og:image`,
          content: image ? `${site.siteMetadata.siteUrl}${image}` : null
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: "robots",
          content: `index${large && ", max-image-preview:large"}`
        }
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  large: Boolean
};

export default SEO;
